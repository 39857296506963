import React from "react";
import speaker from "../../../../public/img/event_speaker_section/speaker.svg";
import { useEffect } from "react";
import { getSpeakers } from "../../../crud/dedicatedEvent-crud";
import { useState } from "react";
import ReadMore from "../../../components/ReadMoreText";
import { sampleSpeakersData } from "../../../data/sampleEvent";
import { errorToaster } from "../../../helpers/utils";
import ImageContainer from "./TrendyImageContainer";

const EventSpeakers = ({ eventUrl, eventData, samplePage, matchedEventTranslationConfig }) => {
  const [speakerData, setSpeakerData] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const [speakers, setSpeakers] = useState([]);


  const getAllSpeakers = async () => {
    try {
      const res = await getSpeakers(eventUrl);
      let speakerData = res.data.sort((a, b) => {
        return a.speaker_order - b.speaker_order;
      });
      setSpeakers(speakerData);
    } catch (error) {
      errorToaster(
        "Something went wrong while trying to get Speaker, please try again later"
      );
    }
  };

  useEffect(() => {
    if (samplePage) {
      setSpeakers(sampleSpeakersData);
    } else {
      getAllSpeakers();
    }
  }, [eventUrl]);


  return (
    <div id="speakers">
      <div className="speaker_heading_description eventpage-fixed-padding">
        {eventData?.speaker_section_title ? (
          <div className="d-flex justify-content-sm-center justify-content-start align-items-center speaker_title_wrapper">
            <div className="event-heading">
              {eventData?.speaker_section_title}
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center speaker_title_wrapper">
            <div className="event-heading">Event</div>
            <div className="speaker-heading-wrapper d-flex align-items-center">
              <div className="speaker-heading">Speakers</div>
              <img className="image_speaker" src={speaker} alt="Speaker" />
            </div>
          </div>
        )}

        {eventData?.speaker_section_description && (
          <div
            className={`d-flex justify-content-center mt-4 speaker_section_description`}
          >
            <ReadMore
              content={eventData.speaker_section_description}
              maxChar={"auto"}
              styles={{
                fontSize: "16px",
                fontWeight: "400",
                color: "rgba(20, 27, 37, 1)",
              }}
            />
          </div>
        )}
      </div>
      <div className="speakers_main d-flex flex-wrap justify-content-center align-items-center">


        {speakers.map((each, index) => (
          <ImageContainer key={index} each={each} speakerData={speakerData} setSpeakerData={setSpeakerData} openModal={openModal} setOpenModal={setOpenModal} matchedEventTranslationConfig={matchedEventTranslationConfig} />
        ))}
      </div>

      <style jsx global>
        {`
          #speakers {
            position: relative;
          }
          .speaker_heading_description {
            background-color: #fff;
            
          }
          .tag-header{
          font-family: "Prompt";
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #141B25;
        }
        .tag-container{
          background-color: #FFF2F1;
          height: 28px;
          padding: 0px 12px 0px 12px;
          border-radius: 4px;
        }
        .tag-name{
          font-family: "Hind";
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #212529;
        }
          .speaker-box {
            gap: 20px;
          }
          .desc {
              font-family: "Prompt";
              font-weight: 400;
              color: #212529;
              font-size: 16px;
              overflow-wrap: break-word;
            }

          .speaker-about {
            gap: 10px;
          }
          .card-box2 {
            height: 456px;
            width: 100%;

          }
          .card-box {
            width: 100% !important;
          }

          .speaker-name2 {
            font-family: "Prompt";
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #fb5850;
          }
          .speaker-designation2 {
            font-family: "Prompt";
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            color: #212529;
          }
          .twitter{
            height:24px;
          }
          .speaker-organisation2 {
            font-family: "Prompt";
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            color: #212529;
          }
          .speakers_main {
            background-color: #260E20;
            padding-left: 6rem;
            padding-right: 6rem;
            padding-bottom: 6rem;
          }
          .event-heading {
            font-family: "Prompt";
            font-style: normal;
            font-weight: 700;
            font-size: 64px;
            line-height: 130%;
            text-align: center;
            color: #572148;
          }
          .speaker-heading-wrapper {
            margin-left: 40px;
          }
          .speaker-heading {
            font-family: "Caveat Brush";
            font-style: normal;
            font-weight: 400;
            font-size: 64px;
            line-height: 130%;
            color: #fb5850;
            margin-right: 10px;
          }
          .speaker_title_wrapper {
            margin-bottom: ${!eventData?.speaker_section_description
            ? "50px"
            : "40px"};
          }
          .speaker_section_description {
            margin-bottom: 100px;
          }
          .card-box2 {
            width: 100%;
          }
          .people_wrapper {
            margin-top: 1.5rem;
          }
          .people {
            width: 265px;
            height: 300px;
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;
          }
          .gray {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            -webkit-filter: grayscale(100%);
            z-index: 99;
            width: 265px;
            height: 300px;
            backdrop-filter: blur(0px);
          }
          .shadow-card {
            background: linear-gradient(
              360deg,
              rgb(0 0 0 / 72%) 0%,
              rgb(0 0 0 / 0%) 100%
            );
            position: absolute;
            top: 235px;
            bottom: 0;
            left: 0;
            right: 0;
          }
          .bio_box {
            background: none;
            color: #ffffff;
            width: 265px;
            padding-left: 30px;
            border-right: 4px solid #fb5850;
            z-index: 99;
            position: relative;
            right: 0;
            top: 265px;
            left: -20px;
            bottom: 0;
          }
          .speaker-name {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.04em;
            margin-left: 5px;
          }
          .speaker-designation {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.04em;
            margin-left: 5px;
          }
          .people:hover > .gray {
            display: none;
          }
          .people:hover > .bio_box {
            background: #fb5850;
            z-index: 20;
          }
          .web-view {
            display: flex;
            margin-top: -30px;
          }
          .image_container_wrapper {
            margin-top: -215px;
            margin-bottom: 200px;
          }
          .website {
            width: 22px;
          }
          
          @media only screen and (min-width: 1145px) {
            .wrapper {
              display: none;
            }
            .card-box2 {
              height: 456px;
              width: 100%;
            }
            .mob_view {
              display: none;
            }
          }
          @media only screen and (min-width: 900px) {
            .card-box2 {
              height: 456px;
              width: 100%;
            }
          }
          @media only screen and (max-width: 1343px) {
            
            .card-box2 {
              height: 456px;
              width: 100%;
            }
          }
          @media only screen and (max-width: 1145px) {
            .speakers_main {
              padding-left: 0rem;
              padding-right: 0rem;
            }
            .card-box2 {
              height: 456px;
              width: 100%;
            }
            .web-view {
              display: none;
            }
            .wrapper {
              margin-top: -130px;
              margin-bottom: 100px;
            }
            .wrapper_mobile {
              margin-top: -40px;
              margin-bottom: 110px;
            }
            .facebook_wrapper,
            .twitter_wrapper {
              margin-left: 10px;
            }
          }
          @media only screen and (max-width: 769px) {
            .speaker-box {
              flex-direction: column;
              align-items: start !important;
            }
            .card-box {
              height: 630px !important;
              width: 300px !important;
            }
            .speakers_main {
              padding-left: 5rem;
              padding-right: 5rem;
            }
            .speaker_heading_description {
              padding-left: 3rem;
              padding-right: 3rem;
            }
            .speaker_title_wrapper {
              margin-bottom: ${!eventData?.speaker_section_description
            ? "110px"
            : "30px"};
            }
            .speaker_section_description {
              margin-bottom: ${speakers.length === 1 ? "-25px" : "70px"};
            }
            .desc {
              font-family: "Prompt";
              font-size: 16px;
              font-weight: 400;
              color: #212529;
              overflow-wrap: break-word;
            }
          }
          @media only screen and (max-width: 647px) {
            .speaker-box {
              flex-direction: column;
              align-items: start !important;
            }
            .card-box {
              height: 630px !important;
              width: 320px !important;
            }
            
            .speaker-name {
              font-size: 14px;
              line-height: 20px;
            }
            .speaker-designation {
              font-size: 10px;
              line-height: 14px;
            }
            .desc {
              font-family: "Prompt";
              font-size: 14px;
              font-weight: 400;
              overflow-wrap: break-word;
              color: #212529;
            }
          }
          @media (max-width: 600px) {
            .card-box {
              height: 630px !important;
              width: 320px !important;
            }
            .speaker-box {
              flex-direction: column;
              align-items: start !important;
            }
            .speaker-about {
              font-size: 14px !important;
              line-height: 20px !important;
            }
            organiser-image-cont-2 {
              width: 120px !important;
              border-radius:4px !important;
            }
            organiser-image-cont-2 img {
              border-radius:4px !important;
            }
            .speaker-image2 {
              width: 120px !important;
              border-radius: 4px;
            }
            .speaker-name2 {
              font-family: "Prompt";
              font-weight: 500;
              font-size: 20px;
              line-height: 30px;
              color: #fb5850;
            }
            .speaker-designation2 {
              font-family: "Prompt";
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              color: #212529;
            }
            .speaker-organisation2 {
              font-family: "Prompt";
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              color: #212529;
            }
            .desc {
              font-family: "Prompt";
              font-weight: 400;
              overflow-wrap: break-word;
              color: #212529;
            }
            .speaker_section_description {
              margin-bottom:35px;
            }
          }
          @media only screen and (max-width: 575px) {
            .event-heading {
              font-size: 18px !important;
              font-weight: 600;
            }
            .speaker-name{
              font-size: 11.11px !important;
            }
             .speaker-designation {
              font-size: 8.8px;
              line-height: 14px;
            }
            .eventpage-fixed-padding{
              padding: 2rem 1.5rem 1rem 1.5rem !important;
            }
            .speaker_title_wrapper {
                margin-bottom: 15px;
              }
              
              
              .speaker-designation2 {
                font-family: "Prompt";
                font-weight: 300;
                font-size: 14px;
              }
              .speaker-about {
                gap: 5px;
              }
            }
          @media only screen and (max-width: 480px) {
            .card-box {
              height: 630px !important;
              width: 320px !important;
            }
            .speakers_main {
              padding-left: 2rem;
              padding-right: 2rem;
            }
            .speaker_heading_description {
              padding-left: 2rem;
              padding-right: 2rem;
            }
            .speaker-heading {
              font-size: 40px;
              margin-right: 0px;
            }
            .speaker-heading-wrapper {
              margin-left: 10px;
            }
            .image_speaker {
              height: 85px;
              width: 80px;
            }
            .speaker-name {
              font-size: 14px;
              line-height: 20px;
            }
            .speaker-designation {
              font-size: 8.8px;
              line-height: 14px;
            }
            .linkedIn,
            .facebook,
            .twitter,
            .website {
              width: 15px;
            }
            .desc {
              font-family: "Prompt";
              font-size: 14px;
              font-weight: 400;
              overflow-wrap: break-word;
              color: #212529;
            }
            .speakers_main{
              margin-top: clamp(50px, 5vw, 140px); 
              margin-bottom: clamp(10px, 5vw, 40px);
            }
            
          }
          @media only screen and (max-width: 376px) {
            .card-box {
              height: 630px !important;
              width: 320px !important;
            }
            
            .desc {
              font-family: "Prompt";
              font-size: 14px;
              font-weight: 400;
              overflow-wrap: break-word;
              color: #212529;
            }
          }
          @media only screen and (max-width: 321px) {
            .card-box {
              height: 630px !important;
              width: 300px !important;
            }
            .speakers_main {
              padding-left: 1rem;
              padding-right: 1rem;
            }
          }
        `}
      </style>
    </div>
  );
};

export default EventSpeakers;