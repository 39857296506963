import React, { useContext } from 'react'
import Link from "next/link";
import eventContext from '../../../../contexts/EventContext'
import moment from 'moment-timezone';


const EventTitle = ({ buttonText, hasCoupons, ticketData }) => {
    const { eventData } = useContext(eventContext)
    let event_start_timestamp = `${eventData.start_date} ${eventData.start_time}`;
    let event_end_timestamp = `${eventData.end_date} ${eventData.end_time}`;
    const localTimezone = moment.tz.guess();

    const date = {
        start_month: moment.utc(event_start_timestamp).local().format("MMM Do"),
        end_month: moment.utc(event_end_timestamp).local().format("MMM Do"),
        start_day: moment.utc(event_start_timestamp).local().format("ddd"),
        end_day: moment.utc(event_end_timestamp).local().format("ddd"),
        start_year: moment.utc(event_start_timestamp).local().format("YYYY"),
        end_year: moment.utc(event_end_timestamp).local().format("YYYY"),
        start_time: moment.utc(event_start_timestamp).local().format("h:mm A"),
        end_time: moment.utc(event_end_timestamp).local().format("h:mm A"),
        timezone: moment.tz(event_end_timestamp, localTimezone).format("z"),
    };


    const getTimeZone = () => {
        if (date.timezone.includes("+")) {
            return `GMT${date.timezone}`
        } else {
            return date.timezone;
        }
    }

    const isSameDate = (dateValue) => {
        if ((dateValue.start_month == dateValue.end_month) && (dateValue.start_year == dateValue.end_year)) {
            return `${dateValue.start_month}, ${dateValue.start_year} ${dateValue.start_time} to ${dateValue.end_time} ${getTimeZone()}`
        } else {
            return `${dateValue.start_month}, ${dateValue.start_year} ${dateValue.start_time} - ${dateValue.end_month}, ${dateValue.end_year} ${dateValue.end_time} ${getTimeZone()}`
        }
    }
    const isSameDateWithoutYear = ({ start_month, end_month, start_time, end_time }) =>
        `${start_month} ${start_time}${start_month === end_month ? ` to ${end_time}` : ` - ${end_month} ${end_time}`} ${getTimeZone()}`;


    const checkIfDisabled = (buttonText) => {
        if (buttonText === "Sold Out" || buttonText === "Expired") {
            return "disabled-btn-title"
        } else return ""
    }

    return (
        <>
            <div className="col-xl-6 col-md-12 pt-2 pt-sm-4 mt-lg-3 d-flex flex-column  position-relative ms-0 ms-sm-3 pl-0 ps-0 pe-0">
                <div className="spark-image">
                    <img src="/img/spark.svg" alt="This is an orange picture showing a spark" />
                </div>
                <div className='col-md-12' >
                    <h1 className="event-title col-md-12 col-12 col-xl-12 mt-3 ">
                        {eventData.name}
                    </h1>
                </div>
                <span className="event-date d-none d-sm-block  mt-1 mb-1 mt-sm-3 mb-3 ">{isSameDate(date)}</span>
                <span className="event-date d-block d-sm-none mt-1 mb-1 mt-sm-3 mb-3 ">{isSameDateWithoutYear(date)}</span>
                {(eventData.city && !eventData.is_virtual) && <Link href={"#venue"} scroll={false}>
                    <span style={{ cursor: "pointer" }} className='mt-sm-3 mt-2'> <i style={{ fontSize: '18px', color: '#F63C3D' }} className="fa fa-map-marker-alt location-icon" aria-hidden="true"></i> <span className="event-city">{eventData.city}, {eventData.country}</span></span>
                </Link>}
                {(eventData.is_virtual && eventData.event_live_link) ? (
                    <a href={eventData.event_live_link} target='_blank' className='text-decoration-none text-white mt-3'>
                        <span style={{ cursor: "pointer" }} className='align-items-center d-flex'>
                            <i style={{ fontSize: '26px', color: '#F63C3D' }} className="fa-solid fa-globe" aria-hidden="true"></i> <span className="event-city ms-2">Online</span>
                        </span></a>) : (eventData.is_virtual && !eventData.event_live_link) ? (
                            <span className='mt-3'>
                                <i style={{ fontSize: '26px', color: '#F63C3D' }} className="fa-solid fa-globe" aria-hidden="true"></i> <span className="event-city ms-2">Online</span>
                            </span>
                        ) : ""}



                <div className="trendy-template-cta-wrapper mt-4">
                    {(ticketData?.length > 0 || hasCoupons) && <Link href={"#tickets"} scroll={false} className='m-0 p-0'>
                        <div className={`buy-btn-2 site-link-text text-white text-center text-decoration-none buy-btn p-3 rounded me-3 ${checkIfDisabled(buttonText)}`} id="register-btn-evtpage-header"
                            onClick={(e) => {
                                e.preventDefault();
                                const ticketsSection = document.getElementById('tickets');
                                if (ticketsSection) {
                                    ticketsSection.scrollIntoView({ behavior: 'smooth' });
                                }
                            }}>
                            {buttonText}
                        </div>
                    </Link>}
                    {eventData.event_website ?
                        <a href={eventData.event_website} target="_blank" className="site-link-text text-white text-decoration-none text-center ">
                            <div className="col-12 site-link-btn align-items-center justify-content-around border border-2 rounded p-3">
                                Official Website
                                <i className="fa fa-external-link fa-lg text-white website-icon ms-3" aria-hidden="true"></i>
                            </div>
                        </a>
                        : null}

                </div>
            </div>
            <style>{`
            .org-title{
                font-family: Caveat Brush;
                font-style: normal;
                font-weight: normal;
                font-size: 35px;
                line-height: 130%;
                color: #FB5850;
            }
            .disabled-btn-title {
                background: #a09f9f !important;
                cursor: not-allowed;
                pointer-events: none;
            }
            .spark-image{
                transform: rotate(-93.88deg);
                position: absolute;
                left: -34px;
                top: 24px;
            }
            .event-title{
                font-family: Prompt;
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 130%;
                color: #FFFFFF;
                word-break: break-word !important;
            }
            .event-date{
                font-family: Prompt;
                font-style: normal;
                font-weight: 400
                font-size: 18px;
                line-height: 130%;
                color: #FB5850;
            }
            .event-city{
                font-family: Prompt;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 130%;
                color: #FFFFFF;
            }
            .website-icon {
                font-size: 1.4rem;
            }
            .event-time{
                font-family: Prompt;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 130%;
                color: #FFFFFF;
            }
            .site-link-btn{
                display: flex;
                width: 100%;
                cursor: pointer;
                padding-left: 10px;
                padding-right: 10px;
                box-shadow: 0px 4px 5px rgb(0 0 0 / 15%);
                max-height: 59px;
                font-size: 16px;
                font-weight: 500;
                max-height: 59px !important;
                height: 59px;
            }
            .site-link-btn:active{
                box-shadow: none;
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }
            .site-link-btn:hover{
                background: #F63C3D;
                border: 3px solid #F63C3D !important;
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }
            .site-link-text{
                font-family: Prompt;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 27px;
                letter-spacing: 0.04em;
                display: inline-block;
                padding: 0px;
                width: auto;
            }
            .buy-btn:hover {
                color: black !important;
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }
            .buy-btn{
                width: 180px;
                background-color: #FB5850;
                cursor: pointer;
                box-shadow: 0px 4px 5px rgb(0 0 0 / 15%);
            }
            .buy-btn:active{
                box-shadow: none;
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }
            .rm-typography{
                margin: 0 !important;
                padding: 0 !important;
              }
              .rm-text-container{
                margin: 0 !important;
                padding: 0 !important;
              }
              .rm-action-button-container *{
                border: none !important;
                margin: 0 !important;
                padding: 0 !important;
                background:transparent;
                text-decoration:underline;
                line-height:0%;
                margin-bottom:10px !important;
                color: #fb5850;
              }
              .rm-action-button-container *:focus {
                box-shadow: none !important;
              }
              .trendy-template-cta-wrapper {
                display: flex;
                font-size: 18px;
              }
            @media only screen and (max-width: 576px) {
                .org-title{
                    font-size: 30px;
                }
                .event-title{
                    font-size: 18px !important;
                    font-weight: 500;
                    line-height: 27px !important;
                    letter-spacing: 0.5px;
                }
                .event-date{
                    font-size: 14px;
                    letter-spacing:0;
                }
                .event-city{
                    font-size: 14px;
                    font-weight: 400;
                }
                .location-icon{
                    font-size: 15px !important;
                }
                .spark-image{
                    display:none;
                }
                .site-link-btn{
                    width:100%;
                }
                .website-icon{
                    font-size:1rem 
                }
             }
                `}
            </style>
        </>
    )
}

export default EventTitle
