import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const NewSideViewModal = ({ children, isOpen, setIsOpen, background = "#FFF", isMenu = false , sponsorModal = false }) => {
    return (
        <>
            <AnimatePresence>
                {isOpen && (
                    <>
                        {/* Modal Content */}
                        <motion.div
                            className="position-fixed d-flex justify-content-center align-items-center h-100 vw-100 top-0 start-0 side-view-modal-container"
                            initial={{ x: "-100%" }}
                            animate={{ x: 0 }}
                            exit={{ x: "-100%" }}
                            transition={{ type: "easeInOut", bounce: 0, duration: 0.5 }}
                        >
                            <div className="side-view-modal-content" style={{ background }}>
                                {children}
                            </div>
                        </motion.div>

                        {/* Overlay */}
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}
                            onClick={() => setIsOpen(false)}
                            style={{ background: "rgba(0, 0, 0, 0.75)", zIndex: 3001 }}
                            className="position-fixed vh-100 vw-100 top-0 start-0"
                        />
                    </>
                )}
            </AnimatePresence>

            {/* Styling */}
            <style>{`
                .side-view-modal-container {
                    z-index: 3002;
                }

                .side-view-modal-content {
                    max-width: ${sponsorModal ? "890px" : "770px"};
                    width: 90%;
                    height: auto;
                    min-width: 320px;
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                }

                @media (max-width: 768px) {
                    .side-view-modal-content {
                        width: 95%;
                        height: auto;
                        max-height: 90%;
                        overflow-y: auto;
                    }
                }

                @media (max-width: 480px) {
                    .side-view-modal-content {
                        width: 100%;
                        max-height: 90%;
                        overflow-y: auto;
                    }
                }
            `}</style>
        </>
    );
};

export default NewSideViewModal;
