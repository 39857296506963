import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import speaker from '../../../../public/img/event_speaker_section/speaker.svg';
import { getExhibitors } from "../../../crud/dedicatedEvent-crud";
import { errorToaster } from "../../../helpers/utils";
import { sampleSponsorsData } from "../../../data/sampleEvent";
import SideViewSponsorTrendy from "../GeneralComponents/SponsorTrendyModal";
import TippyIcon from "../../../components/TippyIcon";

const EventExhibitors = ({ eventUrl, eventData, samplePage, disableOnClick, matchedEventTranslationConfig }) => {

    const [exhibitors, setExhibitors] = useState(null);

    const [selectedExhibitorData, setSelectedExhibitorData] = useState(null);

    const [openModal, setOpenModal] = useState(false);

    const handleClick = (exhibitorData) => {
        // Check if onClick should be disabled
        if (!disableOnClick) {
            setSelectedExhibitorData(exhibitorData);
            setOpenModal(prev => !prev);
        }
    };


    const getAllExhibitors = async () => {
        try {
            const res = await getExhibitors(eventUrl);
            setExhibitors(res.data);
        } catch (error) {
            errorToaster("Something went wrong while trying to get Exhibitors, please try again later");
        }
    }

    useEffect(() => {
        if (samplePage) {
            setExhibitors(sampleSponsorsData)
        } else {
            getAllExhibitors();
        }
    }, [eventUrl, samplePage]);

    return (
        <div id="exhibitors">
            <div className="exhibitors_heading_description eventpage-fixed-padding">

                {/* sponsor title & description ---------------------*/}
                {
                    eventData?.exhibitor_section_title ?
                        <div className='d-flex justify-content-sm-center  justify-content-start align-items-center'>
                            <h2 className="event-heading">{eventData?.exhibitor_section_title}</h2>
                        </div>
                        :
                        <div className='d-flex justify-content-center align-items-center'>
                            <h2 className="event-heading">Event</h2>
                            <div className="speaker-heading-wrapper d-flex align-items-center">
                                <div className="speaker-heading">Exhibitors</div>
                                <img className="image_speaker" src={speaker} alt='Speaker' />
                            </div>
                        </div>
                }
                {
                    eventData?.exhibitor_section_description &&
                    <div className="d-flex justify-content-center mt-4 text-sm-center exhibitor-description" style={{ color: "#141B25" }}>
                        <div
                            style={{ fontSize: "16px", color: "#141B25" }}
                            dangerouslySetInnerHTML={{ __html: eventData.exhibitor_section_description?.replace(/(?:\r\n|\r|\n)/g, '') || '' }}
                        />
                    </div>
                }

                {/* exhibitors Non-Categories with exhibitors ----------------*/}
                {
                    exhibitors?.uncategorized?.length > 0 &&
                    <div className="">
                        {
                            exhibitors?.uncategorized?.reduce((rows, key, index) => (index % 4 === 0 ? rows.push([key])
                                : rows[rows.length - 1].push(key)) && rows, [])?.map((each, index) => (
                                    <>
                                        <div className="padding_down_category_name" />
                                        <div className="mt-4 mb-sm-5 row ps-0 pe-0 exhibitor_card_container">
                                            {
                                                each?.map((each, index) => (
                                                    <div className="exhibitor_card_wrapper_2 col-lg-3 col-sm-6 col-6 d-flex flex-column align-items-center">
                                                        <TippyIcon content={each?.entity_name}>
                                                            <img className="exhibitor_card" src={each?.image_url} alt={each?.entity_name} onClick={() => { (each.description || each.tags.length > 0 || each.booth_number) ? handleClick(each) : each.website_url && window.open(each.website_url, "_blank") }} />
                                                        </TippyIcon>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </>
                                ))
                        }
                    </div>
                }

                {/* exhibitors Categories with exhibitors ----------------*/}
                {
                    exhibitors?.categorized?.map((each, index) => (
                        each?.entity?.length > 0 && (
                            <div className={`${index === 0 ? 'exhibitor_category_root' : 'exhibitor_category_root_1'}`}>
                                <div className="exhibitor_category_name text-start text-sm-center">{each?.category_name}</div>
                                <div className="mt-4 mb-4 row ps-0 pe-0 exhibitor_card_container">
                                    {
                                        each?.entity?.map((each, index) => (
                                            <>
                                                <div className="exhibitor_card_wrapper_2 col-lg-3 col-sm-6 col-6 d-flex flex-column align-items-center" >
                                                    <TippyIcon content={each?.entity_name}>
                                                        <img className="exhibitor_card" src={each?.image_url} alt={each?.entity_name} onClick={() => { (each.description || each.tags.length > 0 || each.booth_number) ? handleClick(each) : each.website_url && window.open(each.website_url, "_blank") }} />
                                                    </TippyIcon>
                                                </div>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                    ))
                }
            </div>
            {selectedExhibitorData && (
                <SideViewSponsorTrendy
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    modalData={selectedExhibitorData}
                    matchedEventTranslationConfig={matchedEventTranslationConfig}
                />
            )}

            <style jsx global>{`
                    #speakers {
                        position: relative;
                    }
                    .exhibitors_heading_description {
                        background-color: #fff;
                    }
                    .event-heading {
                        font-family: 'Prompt';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 64px;
                        line-height: 130%;
                        text-align: center;
                        color: #572148;
                    }
                    .speaker-heading-wrapper {
                        margin-left: 40px;
                    }
                    .speaker-heading {
                        font-family: 'Caveat Brush';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 64px;
                        line-height: 130%;
                        color: #FB5850;
                        margin-right: 10px;
                    }
                    .exhibitor_category_name {
                        font-family: 'Prompt';
                        font-weight: 500;
                        font-size: 40px;
                        line-height: 120%;
                        color: #572148;
                    }
                    .padding_down_category_name {
                        padding: 20px;
                    }
                    .exhibitor_card_container {
                        display: flex;
                        justify-content: center;
                    }
                    .exhibitor_card_wrapper_2 {
                        margin-bottom: 50px;
                    }
                    .exhibitor_card {
                        width: 263.8px;
                        height: 145.36px;
                        border-radius: 14.3478px;
                        border: 1.43478px solid #DDDDDD;
                        cursor: pointer;
                    }
                    .exhibitor_name_text {
                        color: #572148;
                    }
                    .exhibitor_category_root {
                        margin-top: 30px;
                    }
                    .exhibitor_category_root_1 {
                        margin-top: -25px;
                    }
                    @media only screen and (max-width: 1025px) {
                        .padding_down_category_name {
                            padding: 0px;
                        }
                        .exhibitor_card_wrapper_2 {
                            margin-bottom: 20px;
                        }
                        .exhibitor_card_wrapper {
                            margin-bottom: 20px;
                        }
                        .exhibitor_card {
                            width: 213.8px;
                            height: 120.36px;
                        }
                        .exhibitor_category_root_1 {
                            margin-top: 20px;
                        }
                        .eventpage-fixed-padding {
                                padding: 2rem 2rem 4rem 2rem;
                            }
                    }
                    @media only screen and (max-width: 575px) {
                        .event-heading {
                            font-size: 18px !important;
                            font-weight: 600;
                        }
                        .eventpage-fixed-padding {
                            padding: 1rem 2rem 4rem 2rem;
                        }
                        .exhibitor_card_wrapper {
                            margin-bottom: 25px;
                        }
                        .exhibitor_card {
                            max-width: 330.8px;
                            width:100%;
                            height: fit-content;
                        }
                        .exhibitor-description{
                            font-size: 14px;
                        }
                        .exhibitor_category_name{
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }

                    @media only screen and (max-width: 480px) {
                        .exhibitors_heading_description {
                            padding-left: 2rem;
                            padding-right: 2rem;
                            padding-top: 0px !important;
                            padding-bottom: 0px !important;
                        }
                        .speaker-heading {
                            font-size: 40px;
                            margin-right: 0px;
                        }
                        .speaker-heading-wrapper {
                            margin-left: 10px;
                        }
                        .image_speaker {
                            height: 85px;
                            width: 80px;
                        }
                        .exhibitor_card {
                            object-fit: cover;
                        }
                        .exhibitors_heading_description {
                            padding: 1rem 2rem 4rem 2rem;
  
                        }
                           
                            
                    }
                `}</style>
        </div>
    )
}

export default EventExhibitors;
