import React from 'react'
import EventTitle from './EventTitle';
import EventLogo from './EventLogo';

const EventHead = ({ eventUrl, hideHighlight, buttonText, hasCoupons, ticketData }) => {
    return (
        <>
            <div className="d-flex flex-column position-relative">
                <div className="cont row d-flex flex-column-reverse d-sm-flex flex-sm-row mx-sm-5 mx-4   mt-lg-2 mt-0 justify-content-around">
                    <EventTitle
                        eventUrl={eventUrl}
                        buttonText={buttonText}
                        hasCoupons={hasCoupons}
                        ticketData={ticketData}
                    />
                    <EventLogo />
                </div>
            </div>
            <style jsx> {`
                .cont{
                    // margin-bottom: ${!hideHighlight ? "300px" : "50px"};
                    margin-bottom: 80px;
                    z-index: 50;
                }
                @media only screen and (max-width: 1200px){
                    .cont{
                        // margin-bottom: ${!hideHighlight ? "350px" : "40px"};
                        margin-bottom: 40px;
                    }
                }
                `}
            </style>
        </>
    )
}

export default EventHead
