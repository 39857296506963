import React from 'react'

const Approval = ({ btnText }) => {
  return (
    <>
      <div className="register-btn d-flex align-items-center justify-content-center py-2 px-4">
        <span className="">{btnText}</span>
      </div>
      <style jsx>
        {`
            .register-btn{
                background: rgba(251, 88, 80, 1);
                // box-shadow: 0px 2.22081px 8.32803px rgba(0, 0, 0, 0.15);
                border-radius: 5px;
                cursor: pointer;
                // border-radius: 20px 20px 50px 40px;
                // clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 100%);
                // transform: rotateX(180deg);
                box-shadow: 0px 4px 5px rgb(0 0 0 / 15%);
                transition: all .2s;
            }
            .register-btn:hover {
              box-shadow: 0px 0px 10px #fb5850;
            }
            .register-btn:active{
                box-shadow: none;
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }
            .register-btn:hover > *{
                color: black !important;
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }   
            .register-btn span{
                font-family: Prompt;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 130%;
                text-align: center;
                color: #fff;
                // transform: rotateX(180deg);
            }
            `}
      </style>
    </>
  )
}

export default Approval;
