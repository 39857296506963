import React, { useState, useContext } from "react";
import {
    TwitterShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton,
} from "react-share";
import moment from "moment";
import Tippy from "@tippyjs/react";
import eventContext from "../../../contexts/EventContext";
import { successToaster } from "../../../helpers/utils";
import { baseUrl } from "../../../config/config";
import "tippy.js/dist/tippy.css";
import { getTranslation } from "../../../helpers/common";

const EventShout = ({ matchedEventTranslationConfig }) => {
    const notify = () => successToaster("Copied to Clipboard");

    const { eventData } = useContext(eventContext);
    const [shareClick, setShareClick] = useState(false);
    const toggleShareOptions = () => {
        if (shareClick === true) return `row share-options p-0 pb-3 px-1`;
        return `d-none`;
    };

    const checkWebsite = (eventData) => {
        if (eventData.event_website === "" || eventData.event_website === null)
            return `${baseUrl}/${eventData.event_url}`;
        else return eventData.event_website.replace(/^https?\:\/\//i, "");
    };
    const shareText = `I'm excited to join ${eventData?.name} happening on ${moment(eventData?.start_date).format('MMMM Do, YYYY')}, organized through KonfHub. Don't miss your chance to attend!\n`;

    return (
        <>
            <div className="container shout-main-container">
                <div className="row py-5 pt-0 shout-container position-relative m-0 d-flex justify-content-center align-items-center">
                    <h1 className="shout-heading d-none d-sm-block"> {matchedEventTranslationConfig?.match ? getTranslation('Shout', matchedEventTranslationConfig?.translateTo) : "Shout"}   </h1>
                    <div className="col-12 justify-content-center align-items-center p-0 shout-banner d-block d-md-flex">
                        <div className="mb-md-0 px-3 pt-3 pt-md-5 pb-md-4 mb-sm-3 mb-1 col-12 d-block d-md-flex justify-content-around align-items-center p-0 shout-desc-container">
                            <div className="col-12 col-md-6 col-lg-6 shout-title d-block d-md-flex flex-column">
                                <h2 className="shout-mob-heading2">
                                    <span className="shout-mob-heading d-block d-sm-none">{matchedEventTranslationConfig?.match ? getTranslation('Shout About', matchedEventTranslationConfig?.translateTo) : "Shout About"} </span>
                                    <span className="d-none d-sm-block">{matchedEventTranslationConfig?.match ? getTranslation('About', matchedEventTranslationConfig?.translateTo) : "About"} </span> "{eventData.name}"
                                </h2>
                            </div>
                            <div className="col-12 col-md-4 shout-socials row pe-sm-2 pe-sm-3 ps-sm-2 ps-sm-3 pt-sm-0">
                                <div className="col-10 d-flex justify-content-center align-items-center">
                                    <Tippy
                                        content={checkWebsite(eventData)}
                                        placement="top"
                                        arrow={true}
                                        className="custom-tippy"
                                    >
                                        <div
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    eventData.event_website !== ""
                                                        ? eventData.event_website
                                                        : `${baseUrl}/${eventData.event_url}`,
                                                );
                                                notify();
                                            }}
                                            className="dotted d-flex justify-content-center align-items-center p-1 mt-sm-0"
                                        >
                                            <span>{checkWebsite(eventData)}</span>
                                        </div>
                                    </Tippy>
                                </div>

                                <div
                                    onClick={() => setShareClick((prev) => !prev)}
                                    className="d-flex justify-content-center align-items-center mt-sm-0 col-2"
                                >
                                    <img
                                        className={`shout-share-btn ${shareClick ? 'shout-share-active' : ""
                                            }`}
                                        src="/img/share-icon.svg"
                                        alt=""
                                    />

                                    <div className={toggleShareOptions()}>
                                        <div className="col-12 pt-1 mb-2">
                                            <span>Share Via</span>
                                        </div>
                                        <div className="col-12 h-75 d-flex justify-content-between px-2">
                                            <EmailShareButton
                                                subject={`New Event - Konfhub Technologies`}
                                                body={shareText}
                                                url={checkWebsite(eventData)}
                                            >
                                                <img
                                                    className="shout-share"
                                                    src="/img/mail-black.svg"
                                                    alt=""
                                                />
                                            </EmailShareButton>
                                            <WhatsappShareButton
                                                title={shareText}
                                                url={checkWebsite(eventData)}
                                            >
                                                <img
                                                    className="shout-share"
                                                    src="/img/whatsapp-black.svg"
                                                    alt=""
                                                />
                                            </WhatsappShareButton>
                                            <FacebookShareButton
                                                quote={shareText}
                                                url={`${baseUrl}/${eventData.event_url}`
                                                }
                                            >
                                                <img className="shout-share" src="/img/fb-black.svg" alt="" />
                                            </FacebookShareButton>
                                            <TwitterShareButton
                                                title={shareText}
                                                url={checkWebsite(eventData)}
                                            >
                                                <img
                                                    className="shout-share"
                                                    src="/img/event-card-twitter.svg"
                                                    alt=""
                                                />
                                            </TwitterShareButton>
                                            <LinkedinShareButton
                                                summary={shareText}
                                                url={checkWebsite(eventData)}
                                            >
                                                <img
                                                    className="shout-share"
                                                    src="/img/lnkdin-black.svg"
                                                    alt=""
                                                />
                                            </LinkedinShareButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* An overlay created so that share options close when clicked outside */}
            {shareClick && (
                <div
                    className="share-options-overlay"
                    onClick={() => setShareClick(false)}
                ></div>
            )}
            <style jsx>
                {`
                .shout-main-container{
                    padding-bottom:5rem !important;
                }
                .shout-container{
                    width: 100%;
                }
                .shout-img{
                    height: 150px;
                }
                .shout-banner {
                    background: url("/img/event-shout.svg");
                    background-size: cover;
                    width: 95%;
                    min-height: 170px;
                }
                .shout-heading {
                    font-family: Caveat Brush;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 155.595px;
                    line-height: 196px;
                    color: #FB5850;
                    margin-bottom: -70px;
                    margin-left: 104px;
                    z-index: 2;
                }
                .shout-title h2{
                    font-family: Prompt;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 25.9324px;
                    line-height: 39px;
                    color: #FFFFFF;
                    position: relative;
                    word-wrap:break-word;
                }
                .shout-socials{
                    height: 70px;
                    background: rgba(251, 88, 80, 1);
                    border-radius: 13px;
                }
                .dotted{
                    height: 40px;
                    width: 100%;
                    border: 2px solid white;
                    border-style: dotted;
                    border-radius: 5px;
                    cursor: pointer;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .dotted span{
                    font-family: Prompt;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13.7584px;
                    line-height: 21px;
                    color: #FFFFFF;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .dotted:hover span{
                    color: black;
                    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                }
                .shout-fb, .shout-twtr, .shout-share, .shout-share-btn{
                    height: 22px;
                    width: 22px;
                    cursor:pointer;
                    filter: invert(0);
                }
                .shout-share-active {
                    filter: invert(1);
                }
                .shout-share-btn:hover {
                    filter: invert(1);
                    transition: invert .15s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                }
                .shout-fb:hover, .shout-twtr:hover, .shout-share:hover{
                    filter: invert(40%) sepia(40%) saturate(1052%) hue-rotate(316deg) brightness(110%) contrast(98%);
                    transition: invert .15s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                }   
                .share-options{
                    position: absolute;
                    right: 50px;
                    bottom: 25px;
                    width: 180px;
                    background: #fff;
                    border-radius: 10px;
                    box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.25);
                    z-index: 4;
                }
                .share-options span{
                    font-size: 12px;
                }  
                .share-options-overlay {
                    color: white;
                    position: fixed; /* Sit on top of the page content */
                    width: 100%; /* Full width (cover the whole page) */
                    height: 100%; /* Full height (cover the whole page) */
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 3;
                  }
                @media only screen and (max-width: 1000px){
                    .shout-heading {
                        margin-bottom: -75px;
                        margin-left: 44px;
                    }
                }

                @media only screen and (max-width: 768px){
                    .shout-container{
                        width: 92%;
                    }
                    .abs{
                        flex-direction: column;
                        background: #572148;
                        height: 50%;
                        width: 95%;
                        border-radius: 25px;
                    }
                    .shout-heading {
                        font-size: 75px;
                        z-index: 2;
                        margin-bottom: -78px;
                        margin-left: 70px;
                        transform: rotate(-1.9deg);
                    }
                    .shout-title h2{
                        font-size: 16px;
                        z-index: 2;
                        top: 20px;
                        margin-bottom: 1.2rem;
                    }
                    .shout-img{
                        display: none;
                    }
                    .shout-socials{
                        background: transparent;
                        position: relative;
                        // top: -170px;
                    }
                    .share-options{
                        bottom: -55px;
                        right: 40px;
                    }
                    .shout-banner {
                        height: auto;
                        background: url("/img/event-shout-mobile.svg");
                        background-size: cover;
                    }
                }
                @media only screen and (max-width: 768px){
                    .shout-heading{
                        margin-bottom: -92px;
                        margin-left: 30px;
                    }
                    .shout-socials{
                    background-color:#fb5850 !important;
                  }
                   .shout-desc-container{
                        padding: 15px 23px 15px 23px !important;
              
                    }
                }
                @media only screen and (max-width: 575px){
                    .shout-main-container{
                        padding-bottom:2rem !important;
                    }
                    .shout-container{
                        width: 100%;
                    }
                    .shout-heading{
                        font-size: 73px;
                        z-index: 2;
                        margin-bottom: -93px;
                        margin-left: 7px;
                    }
                    .shout-desc-container{
                        padding: 20px 18px !important;
                        justify-content: center !important;
                        display: flex !important;
                        flex-direction: column;
                    }
                    .shout-socials{
                    height: 56px;
                    background-color:#fb5850 !important;
                    }
                    .shout-title {
                        text-align:center;
                    }
                    .shout-banner {
                        background:#572148;
                        border-radius: 15px;
                    }
                    .shout-mob-heading {
                        font-family: "Prompt";
                        font-weight: normal;
                        font-size: 16px;
                        font-weight: 400;
                        color: #FB5850 !important;
                        display: inline !important; 
                        margin-right:3px;


                    }
                    .shout-mob-heading2 {
                        font-family: "Prompt";
                        font-size: 16px !important;
                        font-weight: 400 !important;
                        display: inline !important; 
                        text-align: start; 
                    }
                    .shout-title h2{
                        font-size:16px !important
                        font-weight:400 !important;
                        line-height: 24.19px !important;
                        text-align: start;
                        top: 5px;
                    }
                    .shout-title{
                       text-align: start !important;
                    }
                }
                 @media only screen and (max-width: 575px){  
                    .shout-heading{
                       // margin-bottom: -101px;
                    }
                    .shout-title{
                        letter-spacing: 0.1px;
                        margin-bottom:20px;
                    }
                }
                  @media only screen and (max-width: 357px){ 
                     .shout-heading{
                       // margin-bottom: -107px;
                    }
                  }

                `}
            </style>
        </>
    );
};

export default EventShout;
