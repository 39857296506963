import React, { useState, useEffect } from 'react';
import { getWorkshops } from "../../../crud/dedicatedEvent-crud";
import { errorToaster } from "../../../helpers/utils";
import speaker from '../../../../public/img/event_speaker_section/speaker.svg';
import ReadMore from '../../../components/ReadMoreText';
import WorkshopCard from '../../../components/WorkshopCard';
import { sampleWorkshopsData } from '../../../data/sampleEvent';

const EventWorkshop = ({ eventUrl, eventData, samplePage }) => {
    const [workshops, setWorkshops] = useState(null);
    const getWorkshopsFunction = async () => {
        try {
            const res = await getWorkshops(eventUrl);
            setWorkshops(res.data);
        } catch (error) {
            errorToaster("Something went wrong while trying to get Workshop, please try again later");
        }
    }

    useEffect(() => {
        if (samplePage) {
            setWorkshops(sampleWorkshopsData);
        } else {
            getWorkshopsFunction();
        }
    }, [eventUrl])


    return (
        <div id="workshops">
            <div className="speaker_heading_description mt-5 workshop_heading_description  ">

                {/* workshop title & description ---------------------*/}
                {
                    eventData?.workshop_section_title ?
                        <div className='d-flex justify-content-sm-center justify-content-start align-items-center'>
                            <h2 className="event-heading workshop-dynamic-title">{eventData?.workshop_section_title}</h2>
                        </div>
                        :
                        <div className='d-flex justify-content-center align-items-center'>
                            <h2 className="event-heading workshop-fixed-title">Tech</h2>
                            <div className="speaker-heading-wrapper d-flex align-items-center">
                                <div className="speaker-heading workshop-fixed-title">Workshops</div>
                                <img className="image_speaker" src={speaker} alt='Speaker' />
                            </div>
                        </div>
                }
                {
                    eventData?.workshop_section_description &&
                    <div className="d-flex justify-content-center text-sm-center text-start mt-4" style={{ color: "#141B25" }}>
                        <ReadMore
                            content={eventData.workshop_section_description}
                            maxChar={"auto"}
                            styles={{
                                textAlign: "start",
                                fontSize: "16px"

                            }}
                        />
                    </div>
                }
                {
                    workshops && workshops.length == 1 && workshops.map((workshopValue, index) => (
                        <div className="mt-5 workshop-card-if-only-one">
                            <WorkshopCard workshopDetails={workshopValue} eventUrl={eventUrl} />
                        </div>
                    ))
                }
                <div className=" row no-gutter ">
                    {
                        workshops && workshops.length > 1 && workshops && workshops.map((workshopValue, index) => (
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 d-flex justify-content-center mt-5 px-0 px-sm-3">
                                <WorkshopCard workshopDetails={workshopValue} eventUrl={eventUrl} />
                            </div>


                        ))
                    }
                </div>
            </div>

            <style jsx global>
                {`
                .speaker_heading_description {
                    background-color: #fff;
                    padding-bottom: 80px;
                    padding-left: 6rem;
                    padding-right: 6rem;
                }
                .event-heading {
                    font-family: 'Prompt';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 64px;
                    line-height: 130%;
                    text-align: center;
                    color: #572148;
                }
                .speaker-heading-wrapper {
                    margin-left: 40px;
                }
                .speaker-heading {
                    font-family: 'Caveat Brush';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 64px;
                    line-height: 130%;
                    color: #FB5850;
                    margin-right: 10px;
                }
                .workshop-card-if-only-one{
                    width: 500px;
                    display: flex;
                    justify-content: center;
                    margin: auto;
                }
                @media only screen and (max-width: 700px) {
                    .workshop-card-if-only-one{
                        width: 400px;
                    }
                }
                @media only screen and (max-width: 575px) {
                  .event-heading {
                    font-size: 29px;
                  }
                  .workshop-dynamic-title{
                    font-size: 42px;
                  }
                  .workshop-fixed-title{
                    font-size: 42px;
                  }  
                  .speaker_heading_description {
                    padding-left: 2rem;
                    padding-right: 2rem;
                    padding: 0rem 1.5rem 75px 1.5rem;
                }
                }
                @media only screen and (max-width: 550px) {
                    .workshop-dynamic-title{
                        font-family: 'Prompt';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 42px;
                        line-height: 100%;
                        text-align: center;
                        color: #572148;
                    }
                    .workshop-fixed-title{
                        font-family: 'Prompt';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 42px;
                        line-height: 100%;
                        text-align: center;
                        color: #572148;
                    }
                    .workshop-card-if-only-one{
                        width: 300px;
                    }
                    .event-heading {
                        font-size: 29px;
                    }
                    .workshop_heading_description {
                        padding-bottom: 45px !important;
                    }
                    .row.no-gutter {
                        --bs-gutter-x: 0;
                    }
                }
            `}
            </style>
        </div>
    )
}

export default EventWorkshop;
