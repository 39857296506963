
import React from 'react'
import BottomDrawerModal from '../GeneralComponents/BottomDrawerModal';
import TrendySideViewModal from "../GeneralComponents/TrendySideViewModal";
import LinkedIn from "../../../../public/img/event_speaker_section/LinkedIn.svg";
import facebook from "../../../../public/img/event_speaker_section/facebook.svg";
import twitter from "../../../../public/img/event_speaker_section/twitter-logo-white.svg";
import website from "../../../../public/img/event_speaker_section/website.svg";
import { useIsMobile } from '../../../config/customHooks';
import { getTranslation } from "../../../helpers/common";

const ImageContainer = ({ each, index, length, speakerData, setSpeakerData, openModal, setOpenModal, matchedEventTranslationConfig }) => {
  const isMobile = useIsMobile();

  const SocialMediaLinks = ({ each }) => {
    const handleLinkClick = (e) => {
      // Prevent the propagation of the click event
      e.stopPropagation();
    };

    return (
      <div className="mt-1 d-flex align-items-start" style={{ marginLeft: 5 }}>
        {each?.facebook_url && (
          <div className="facebook_wrapper" style={{ marginRight: 20 }}>
            <a href={each.facebook_url} target="_blank" onClick={handleLinkClick}>
              <img className="facebook" src={facebook} alt="facebook" />
            </a>
          </div>
        )}
        {each?.twitter_url && (
          <div className="twitter_wrapper" style={{ marginRight: 20 }}>
            <a href={each.twitter_url} target="_blank" onClick={handleLinkClick}>
              <img className="twitter" src={twitter} alt="twitter" />
            </a>
          </div>
        )}
        {each?.linkedin_url && (
          <div style={{ marginRight: 20 }}>
            <a href={each.linkedin_url} target="_blank" onClick={handleLinkClick}>
              <img className="linkedIn" src={LinkedIn} alt="Linked In" />
            </a>
          </div>
        )}
        {each?.website_url && (
          <div className="twitter_wrapper">
            <a href={each.website_url} target="_blank" onClick={handleLinkClick}>
              <img className="website" src={website} alt="website" />
            </a>
          </div>
        )}
      </div>
    );
  };


  const ModalContent = (
    <div className="p-1 p-md-4 p-lg-4 card-box2">
      <div className="text-end">
        <button
          onClick={() => setOpenModal(false)}
          className="border-0"
          style={{
            background: "#F5F5F5",
            color: "#525252",
          }}
        >
          <i
            className="fa-solid fa-xmark"
            style={{ fontSize: "18px" }}
          ></i>
        </button>
      </div>
      <div className="d-flex mb-4 speaker-box">
        <div className="organiser-image-cont-2 rounded-circle me-3">
          <img
            src={speakerData?.image_url}
            // width={0}
            // height={0}
            // sizes="100vw"
            style={{ width: "180px" }}
            alt={`${speakerData.name} poster image`}
            className="speaker-image2"
          />
        </div>
        <div className="d-flex flex-column speaker-about ">
          <p className="speaker-name2">{speakerData.name}</p>
          <p className="speaker-designation2">
            {speakerData.designation}
          </p>
          <p className="speaker-organisation2">
            {speakerData.organisation}
          </p>
          {speakerData.location && (
            <p className="speaker-organisation2 gap-2 d-flex">
              <img src="/img/prolocation.svg" alt="" />
              {speakerData.location}</p>
          )}
          <div>
            {speakerData.facebook_url && (
              <a
                className="me-2"
                href={speakerData.facebook_url}
                target={"_blank"}
              >
                <img src="/img/facebook03.svg" alt="" />
              </a>
            )}
            {speakerData.twitter_url && (
              <a
                className="me-2"
                href={speakerData.twitter_url}
                target={"_blank"}
              >
                <img src="/img/twitter-red.svg" alt="" />
              </a>
            )}
            {speakerData.linkedin_url && (
              <a
                className="me-2"
                href={speakerData.linkedin_url}
                target={"_blank"}
              >
                <img src="/img/linkedin03.svg" alt="" />
              </a>
            )}
            {speakerData.website_url && (
              <a
                className="me-2"
                href={speakerData.website_url}
                target={"_blank"}
              >
                <img src="/img/Website03.svg" alt="" />
              </a>
            )}
          </div>
        </div>
      </div>

      {speakerData.about &&
        <p
          className="mb-0 desc"
          dangerouslySetInnerHTML={{ __html: `${speakerData.about?.replace(/(?:\r\n|\r|\n)/g, '') || ''}` }}
        />
      }
      {speakerData.tags && speakerData.tags.length > 0 && (
        <div className="speaker-tags">
          <p className="my-3 tag-header">{matchedEventTranslationConfig?.match ? getTranslation('Tags', matchedEventTranslationConfig?.translateTo) : "Tags"}</p>
          <div className="d-flex flex-wrap my-3">
            {speakerData.tags.map((tag) => (
              <div key={tag.id} className="me-2 mb-2 tag-container">
                <span className="tag-name">#{tag.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
  return (
    <>
      <div className="col-xl-3 col-lg-4 col-md-6 col-12 justify-content-center d-flex mt-5">
        <div
          className="people"
          style={{
            backgroundImage:
              each?.image_url && each?.image_url !== ""
                ? `url("${each?.image_url}")`
                : `url()`,
            position: "relative",
            //commented out this part for single line speakers = getMargins(length)[index]
            marginTop: 100,
          }}
          onClick={() => {
            setOpenModal((prev) => !prev);
            setSpeakerData(each);
          }}
        >
          <div className="gray"></div>
          <div className="shadow-card position-absolute end-0 bottom-0 py-2"></div>
          <div className="bio_box position-relative end-0 bottom-0 py-2">
            <div className="speaker-name">{each?.name ? each?.name : ""}</div>
            <div className="speaker-designation">
              {each?.designation ? each?.designation : ""}
            </div>
            <SocialMediaLinks each={each} />
            <div className="p-2" />
          </div>
        </div>

      </div>
      {!isMobile ?
        <TrendySideViewModal
          isOpen={openModal}
          setIsOpen={setOpenModal}
          background="#F5F5F5"
        >
          {ModalContent}
        </TrendySideViewModal>
        :
        <BottomDrawerModal
          isOpen={openModal}
          setIsOpen={setOpenModal}
          background="#F5F5F5"
        >
          {ModalContent}
        </BottomDrawerModal>
      }
      <style jsx global>{`
          #speakers {
            position: relative;
          }
          .speaker_heading_description {
            background-color: #fff;
            
          }
          .speaker-tags{
          padding-bottom: 10px;
        }
          .tag-header{
          font-family: "Prompt";
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #141B25;
        }
        .tag-container{
          background-color: #FFF2F1;
          height: 28px;
          padding: 0px 12px 0px 12px;
          border-radius: 4px;
        }
        .tag-name{
          font-family: "Hind";
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #212529;
        }
          .speaker-box {
            gap: 20px;
          }
          .desc {
              font-family: "Prompt";
              font-weight: 400;
              color: #212529;
              font-size: 16px;
              overflow-wrap: break-word;
            }

          .speaker-about {
            gap: 10px;
          }
          .card-box2 {
            height: 456px;
            width: 100%;

          }
          .card-box {
            width: 100% !important;
          }

          .speaker-name2 {
            font-family: "Prompt";
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #fb5850;
          }
          .speaker-designation2 {
            font-family: "Prompt";
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            color: #212529;
          }
          .twitter{
            height:24px;
          }
          .speaker-organisation2 {
            font-family: "Prompt";
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            color: #212529;
          }
          .speakers_main {
            background-color: #260E20;
            padding-left: 6rem;
            padding-right: 6rem;
            padding-bottom: 6rem;
          }
          .event-heading {
            font-family: "Prompt";
            font-style: normal;
            font-weight: 700;
            font-size: 64px;
            line-height: 130%;
            text-align: center;
            color: #572148;
          }
          .speaker-heading-wrapper {
            margin-left: 40px;
          }
          .speaker-heading {
            font-family: "Caveat Brush";
            font-style: normal;
            font-weight: 400;
            font-size: 64px;
            line-height: 130%;
            color: #fb5850;
            margin-right: 10px;
          }
          .speaker_section_description {
            margin-bottom: 100px;
          }
          .card-box2 {
            width: 100%;
          }
          .people_wrapper {
            margin-top: 1.5rem;
          }
          .people {
            width: 265px;
            height: 300px;
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;
          }
          .gray {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            -webkit-filter: grayscale(100%);
            z-index: 99;
            width: 265px;
            height: 300px;
            backdrop-filter: blur(0px);
          }
          .shadow-card {
            background: linear-gradient(
              360deg,
              rgb(0 0 0 / 72%) 0%,
              rgb(0 0 0 / 0%) 100%
            );
            position: absolute;
            top: 235px;
            bottom: 0;
            left: 0;
            right: 0;
          }
          .bio_box {
            background: none;
            color: #ffffff;
            width: 265px;
            padding-left: 30px;
            border-right: 4px solid #fb5850;
            z-index: 99;
            position: relative;
            right: 0;
            top: 265px;
            left: -20px;
            bottom: 0;
          }
          .speaker-name {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.04em;
            margin-left: 5px;
          }
          .speaker-designation {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.04em;
            margin-left: 5px;
          }
          .people:hover > .gray {
            display: none;
          }
          .people:hover > .bio_box {
            background: #fb5850;
            z-index: 20;
          }
          .web-view {
            display: flex;
            margin-top: -30px;
          }
          .image_container_wrapper {
            margin-top: -215px;
            margin-bottom: 200px;
          }
          .website {
            width: 22px;
          }
          
          @media only screen and (min-width: 1145px) {
            .wrapper {
              display: none;
            }
            .card-box2 {
              height: 456px;
              width: 100%;
            }
            .mob_view {
              display: none;
            }
          }
          @media only screen and (min-width: 900px) {
            .card-box2 {
              height: 456px;
              width: 100%;
            }
          }
          @media only screen and (max-width: 1343px) {
            
            .card-box2 {
              height: 456px;
              width: 100%;
            }
          }
          @media only screen and (max-width: 1145px) {
            .speakers_main {
              padding-left: 0rem;
              padding-right: 0rem;
            }
            .card-box2 {
              height: 456px;
              width: 100%;
            }
            .web-view {
              display: none;
            }
            .wrapper {
              margin-top: -130px;
              margin-bottom: 100px;
            }
            .wrapper_mobile {
              margin-top: -40px;
              margin-bottom: 110px;
            }
            .facebook_wrapper,
            .twitter_wrapper {
              margin-left: 10px;
            }
          }
          @media only screen and (max-width: 769px) {
            .speaker-box {
              flex-direction: column;
              align-items: start !important;
            }
            .card-box {
              height: 630px !important;
              width: 300px !important;
            }
            .speakers_main {
              padding-left: 5rem;
              padding-right: 5rem;
            }
            .speaker_heading_description {
              padding-left: 3rem;
              padding-right: 3rem;
            }
            .desc {
              font-family: "Prompt";
              font-size: 16px;
              font-weight: 400;
              color: #212529;
              overflow-wrap: break-word;
            }
          }
          @media only screen and (max-width: 647px) {
            .speaker-box {
              flex-direction: column;
              align-items: start !important;
            }
            .card-box {
              height: 630px !important;
              width: 320px !important;
            }
            
            .speaker-name {
              font-size: 14px;
              line-height: 20px;
            }
            .speaker-designation {
              font-size: 10px;
              line-height: 14px;
            }
            .desc {
              font-family: "Prompt";
              font-size: 14px;
              font-weight: 400;
              overflow-wrap: break-word;
              color: #212529;
            }
          }
          @media (max-width: 600px) {
            .card-box {
              height: 630px !important;
              width: 320px !important;
            }
            .speaker-box {
              flex-direction: column;
              align-items: start !important;
            }
            .speaker-about {
              font-size: 14px !important;
              line-height: 20px !important;
            }
            organiser-image-cont-2 {
              width: 120px !important;
              border-radius:4px !important;
            }
            organiser-image-cont-2 img {
              border-radius:4px !important;
            }
            .speaker-image2 {
              width: 120px !important;
              border-radius: 4px;
            }
            .speaker-name2 {
              font-family: "Prompt";
              font-weight: 500;
              font-size: 20px;
              line-height: 30px;
              color: #fb5850;
            }
            .speaker-designation2 {
              font-family: "Prompt";
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              color: #212529;
            }
            .speaker-organisation2 {
              font-family: "Prompt";
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              color: #212529;
            }
            .desc {
              font-family: "Prompt";
              font-weight: 400;
              overflow-wrap: break-word;
              color: #212529;
            }
            .speaker_section_description {
              margin-bottom:35px;
            }
          }
          @media only screen and (max-width: 575px) {
            .event-heading {
              font-size: 18px !important;
              font-weight: 600;
            }
            .speaker-name{
              font-size: 11.11px !important;
            }
             .speaker-designation {
              font-size: 8.8px;
              line-height: 14px;
            }
            .eventpage-fixed-padding{
              padding: 2rem 1.5rem 1rem 1.5rem !important;
            }
            .speaker_title_wrapper {
                margin-bottom: 15px;
              }
              
              
              .speaker-designation2 {
                font-family: "Prompt";
                font-weight: 300;
                font-size: 14px;
              }
              .speaker-about {
                gap: 5px;
              }
            }
          @media only screen and (max-width: 480px) {
            .card-box {
              height: 630px !important;
              width: 320px !important;
            }
            .speakers_main {
              padding-left: 2rem;
              padding-right: 2rem;
            }
            .speaker_heading_description {
              padding-left: 2rem;
              padding-right: 2rem;
            }
            .speaker-heading {
              font-size: 40px;
              margin-right: 0px;
            }
            .speaker-heading-wrapper {
              margin-left: 10px;
            }
            .image_speaker {
              height: 85px;
              width: 80px;
            }
            .speaker-name {
              font-size: 14px;
              line-height: 20px;
            }
            .speaker-designation {
              font-size: 8.8px;
              line-height: 14px;
            }
            .linkedIn,
            .facebook,
            .twitter,
            .website {
              width: 15px;
            }
            .desc {
              font-family: "Prompt";
              font-size: 14px;
              font-weight: 400;
              overflow-wrap: break-word;
              color: #212529;
            }
            .speakers_main{
              margin-top: clamp(50px, 5vw, 140px); 
              margin-bottom: clamp(10px, 5vw, 40px);
            }
            
          }
          @media only screen and (max-width: 376px) {
            .card-box {
              height: 630px !important;
              width: 320px !important;
            }
            
            .desc {
              font-family: "Prompt";
              font-size: 14px;
              font-weight: 400;
              overflow-wrap: break-word;
              color: #212529;
            }
          }
          @media only screen and (max-width: 321px) {
            .card-box {
              height: 630px !important;
              width: 300px !important;
            }
            .speakers_main {
              padding-left: 1rem;
              padding-right: 1rem;
            }
          }
        `}</style>
    </>
  );
};

export default ImageContainer;